import React, { Fragment, useEffect, useState } from 'react';
import './LeadManagementPage.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import icon from '../../images/domains/LeadManagement.svg';
import Title from '../../components/title/Title';
import colors from '../../_variables.module.scss';
import Card from '../../components/card/Card';
import illustration01 from '../../images/domains/lead-management/illustration01.svg';
import illustration02 from '../../images/domains/lead-management/illustration02.svg';
import illustration03 from '../../images/domains/lead-management/illustration03.svg';
import illustration04 from '../../images/domains/lead-management/illustration04.svg';
import illustration05 from '../../images/domains/lead-management/illustration05.svg';
import illustration06 from '../../images/domains/lead-management/illustration06.svg';
import illustration07 from '../../images/domains/lead-management/illustration07.svg';
import illustration08 from '../../images/domains/lead-management/illustration08.svg';
import illustration09 from '../../images/domains/lead-management/illustration09.svg';
import illustration10 from '../../images/domains/lead-management/illustration10.svg';
import illustration11 from '../../images/domains/lead-management/illustration11.svg';
import illustration12 from '../../images/domains/lead-management/illustration12.svg';

const LeadManagementPage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("Lead Management");
        setList(
            <Fragment>
                <ul>
                    <p>Lead management is the systematic process of <strong>capturing</strong>, <strong>tracking</strong>, <strong>qualifying</strong>, and <strong>nurturing</strong> <strong>potential</strong> customers or leads throughout their journey, from initial awareness to the point of conversion.</p>
                    <li><strong>Lead Capture:</strong> This involves collecting information about potential customers through various channels such as website forms, social media, or events. </li>
                    <li><strong>Lead Qualification:</strong> Leads are evaluated based on criteria such as demographics, interests, and behaviour to determine their likelihood of becoming customers. </li>
                    <li><strong>Lead Nurturing:</strong> Once qualified, leads are nurtured through personalised communication and targeted content to build relationships and guide them through the sales funnel. </li>
                    <li><strong>Lead Distribution:</strong> Qualified leads are assigned to sales representatives or teams based on criteria like geographic location, industry, or sales capacity. </li>
                    <li><strong>Lead Tracking and Analysis:</strong> Continuous monitoring of lead interactions and outcomes allows for refining lead management strategies, optimising conversions, and improving overall sales performance. </li>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={`Accelerare | ${title}`} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='lead-management-page'>
                    <Title title={title} list={list} icon={icon} />
                    <div className='container first-section  mt-5'>
                        <div className='heading'>Benefits of the Lead Management domain:</div>
                        <div className='row'>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Strategic Marketing"}
                                    content={"Lead management enables targeted marketing efforts, ensuring that promotional activities are tailored to specific audience segments."}
                                    backgroundColor={colors.blueGreenDarker3}
                                    icon={illustration01}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Value Delivery"}
                                    content={"Providing leads with the promised value and a positive experience upon reaching your platform is crucial for converting them into customers."}
                                    backgroundColor={colors.blueGreenDarker3}
                                    icon={illustration02}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Improved Conversion Rates"}
                                    content={"It helps businesses focus on high-potential leads, increasing the likelihood of conversion."}
                                    backgroundColor={colors.blueGreenDarker3}
                                    icon={illustration03}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Customer Understanding"}
                                    content={"It provides insights into customer behaviour, preferences, and needs through data analysis, aiding in more personalised interactions."}
                                    backgroundColor={colors.blueGreenDarker3}
                                    icon={illustration04}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Efficient Resource Allocation"}
                                    content={"By prioritising leads, resources are allocated more effectively, saving time and reducing costs."}
                                    backgroundColor={colors.blueGreenDarker3}
                                    icon={illustration05}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Increased Sales Productivity"}
                                    content={"Sales teams can concentrate on leads that are more likely to convert, improving overall productivity and efficiency."}
                                    backgroundColor={colors.blueGreenDarker3}
                                    icon={illustration06}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Timely Follow-ups"}
                                    content={"Automated processes in lead management ensure timely follow-ups, preventing leads from going cold and increasing engagement."}
                                    backgroundColor={colors.blueGreenDarker3}
                                    icon={illustration07}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Enhanced Customer Experience"}
                                    content={"Personalised interactions and timely responses contribute to an improved overall customer experience."}
                                    backgroundColor={colors.blueGreenDarker3}
                                    icon={illustration08}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Customer Trust"}
                                    content={"Ensuring smooth interactions with customers is crucial for maintaining a positive perception and building trust."}
                                    backgroundColor={colors.blueGreenDarker3}
                                    icon={illustration09}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Better Collaboration"}
                                    content={"Sales teams can concentrate on leads that are more likely to convert, improving overall productivity and efficiency."}
                                    backgroundColor={colors.blueGreenDarker3}
                                    icon={illustration10}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Data-Driven Decision-Making"}
                                    content={"Lead management relies on data analytics, empowering businesses to make informed decisions based on real-time insights."}
                                    backgroundColor={colors.blueGreenDarker3}
                                    icon={illustration11}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Scalability"}
                                    content={"The processes implemented in lead management are scalable, accommodating the growth of the business and adapting to changing market dynamics."}
                                    backgroundColor={colors.blueGreenDarker3}
                                    icon={illustration12}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                        </div>
                    </div>
                </div>
            </PageLayout>
        </Fragment>
    );
}

export default LeadManagementPage;