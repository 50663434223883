import React, { Fragment, useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import './Navbar.scss';
import Icon from '../../icon.svg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import illustration04 from '../../images/navbar/illustration04.svg';
import illustration05 from '../../images/navbar/illustration05.svg';
import illustration06 from '../../images/navbar/illustration06.svg';
import illustration07 from '../../images/navbar/illustration07.svg';
import illustration08 from '../../images/navbar/illustration08.svg';
import illustration09 from '../../images/navbar/illustration09.svg';
import illustration10 from '../../images/navbar/illustration10.svg';
//import illustration11 from '../../images/navbar/illustration11.svg';
//import illustration12 from '../../images/navbar/illustration12.svg';
//import illustration13 from '../../images/navbar/illustration13.svg';
//import illustration14 from '../../images/navbar/illustration14.svg';
//import illustration15 from '../../images/navbar/illustration15.svg';
//import illustration16 from '../../images/navbar/illustration16.svg';

const Navbar = () => {
    const location = useLocation();
    const [show, setShow] = useState('navbar-collapse collapse');
    const [active, setActive] = useState(window?.location?.pathname || '/');

    useEffect(() => {
        setActive(window?.location?.pathname?.split('/').length > 0 ? `/${window?.location?.pathname?.split('/')[1]}` : '/');
    }, [location]);

    const onBurgerMenuClick = (oneSide = false) => {
        if (window?.innerWidth <= 991) {
            if (show === 'navbar-collapse collapse' && !oneSide) {
                setShow('navbar-collapse collapsing');
                setTimeout(() => setShow('navbar-collapse collapse show'), 100);
            }
            else if (show === 'navbar-collapse collapse show') {
                setShow('navbar-collapse collapsing');
                setTimeout(() => setShow('navbar-collapse collapse'), 100);
            }
        }
    }

    return (
        <Fragment>
            <div className='navbar-component'>
                <nav className="navbar navbar-expand-lg fixed-top">
                    <div className="container">
                        <Link className="navbar-brand" to="/" onClick={() => { onBurgerMenuClick(true); }}>
                            <img src={window?.innerWidth <= 991 ? Icon : Icon} alt="accelerare" width={"100px"} height={"100px"} className="d-inline-block align-text-top" />
                        </Link>
                        <button className="navbar-toggler" onClick={() => onBurgerMenuClick()} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            {/*<span className="navbar-toggler-icon"></span>*/}
                            <div id="nav-icon" className={`menu-icon ${show === 'navbar-collapse collapse show' ? 'open' : ''}`}><span></span><span></span><span></span><span></span></div>
                        </button>
                        <div className={`${show}`} id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                {/*<li className="nav-item nav-divider">
                                    <Link className={`nav-link  ${active === '/' ? 'active' : ''}`} aria-current="page" to="/" onClick={() => { onBurgerMenuClick(); }}>Home</Link>
                                </li>*/}
                                {/*<div className='nav-divider'></div>*/}
                                <li className="nav-item dropdown">

                                    <div className={`nav-link dropdown-toggle ${active === '/domains' || active === '/features' ? 'active' : ''}`} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Domains
                                        <div className='dropdown-indicator'> </div>
                                    </div>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <div className='container'>
                                            <div className='row'>
                                                <div className='col-12 col-sm-6 drop-sub-divider mt-3'>
                                                    <div className='title'>
                                                        Domains
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12 col-sm-6 col-nav-item'>
                                                            <Link aria-current="page" to={`/domains/lead-management`} onClick={() => { onBurgerMenuClick(); }}>
                                                                <div className='col-nav-card'>
                                                                    <div className='icon'>
                                                                        <img src={illustration04} alt='icon' />
                                                                    </div>
                                                                    <div className='content'>
                                                                        <div className='heading'>
                                                                            Lead Management <ArrowForwardIcon />
                                                                        </div>
                                                                        <div className='text'>
                                                                            Complete Heuristic review of your platform.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className='col-12 col-sm-6 col-nav-item'>
                                                            <Link aria-current="page" to={`/domains/inventory-management`} onClick={() => { onBurgerMenuClick(); }}>
                                                                <div className='col-nav-card'>
                                                                    <div className='icon'>
                                                                        <img src={illustration05} alt='icon' />
                                                                    </div>
                                                                    <div className='content'>
                                                                        <div className='heading'>
                                                                            Inventory Management <ArrowForwardIcon />
                                                                        </div>
                                                                        <div className='text'>
                                                                            Suggestions made to improve suboptimal areas.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className='col-12 col-sm-6 col-nav-item'>
                                                            <Link aria-current="page" to={`/domains/customer-relations`} onClick={() => { onBurgerMenuClick(); }}>
                                                                <div className='col-nav-card'>
                                                                    <div className='icon'>
                                                                        <img src={illustration06} alt='icon' />
                                                                    </div>
                                                                    <div className='content'>
                                                                        <div className='heading'>
                                                                            Customer Relations <ArrowForwardIcon />
                                                                        </div>
                                                                        <div className='text'>
                                                                            Complete Heuristic review of your platform.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className='col-12 col-sm-6 col-nav-item'>
                                                            <Link aria-current="page" to={`/domains/listing-management`} onClick={() => { onBurgerMenuClick(); }}>
                                                                <div className='col-nav-card'>
                                                                    <div className='icon'>
                                                                        <img src={illustration07} alt='icon' />
                                                                    </div>
                                                                    <div className='content'>
                                                                        <div className='heading'>
                                                                            Listing Management <ArrowForwardIcon />
                                                                        </div>
                                                                        <div className='text'>
                                                                            Complete Heuristic review of your platform.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className='col-12 col-sm-6 col-nav-item'>
                                                            <Link aria-current="page" to={`/domains/website-management`} onClick={() => { onBurgerMenuClick(); }}>
                                                                <div className='col-nav-card'>
                                                                    <div className='icon'>
                                                                        <img src={illustration08} alt='icon' />
                                                                    </div>
                                                                    <div className='content'>
                                                                        <div className='heading'>
                                                                            Website Management <ArrowForwardIcon />
                                                                        </div>
                                                                        <div className='text'>
                                                                            Complete Heuristic review of your platform.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>                                 
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-6 mt-3'>
                                                    <div className='title'>
                                                        Features
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12 col-sm-6 col-nav-item'>
                                                            <Link aria-current="page" to={`/features/experiments`} onClick={() => { onBurgerMenuClick(); }}>
                                                                <div className='col-nav-list'>
                                                                    <div className='icon'>
                                                                        <img src={illustration09} alt='icon' />
                                                                    </div>
                                                                    <div className='content'>
                                                                        <div className='heading'>
                                                                            Experiments
                                                                        </div>
                                                                    </div>
                                                                    <ArrowForwardIcon />
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className='col-12 col-sm-6 col-nav-item'>
                                                            <Link aria-current="page" to={`/features/whatsapp-integration`} onClick={() => { onBurgerMenuClick(); }}>
                                                                <div className='col-nav-list'>
                                                                    <div className='icon'>
                                                                        <img src={illustration10} alt='icon' />
                                                                    </div>
                                                                    <div className='content'>
                                                                        <div className='heading'>
                                                                            Whatsapp Integration
                                                                        </div>
                                                                    </div>
                                                                    <ArrowForwardIcon />
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        {/*<div className='col-12 col-sm-6 col-nav-item'>
                                                            <Link aria-current="page" to={`/features/artificial-intelligence`} onClick={() => { onBurgerMenuClick(); }}>
                                                                <div className='col-nav-list'>
                                                                    <div className='icon'>
                                                                        <img src={illustration11} alt='icon' />
                                                                    </div>
                                                                    <div className='content'>
                                                                        <div className='heading'>
                                                                            Artificial intelligence
                                                                        </div>
                                                                    </div>
                                                                    <ArrowForwardIcon />
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className='col-12 col-sm-6 col-nav-item'>
                                                            <Link aria-current="page" to={`/features/lead-qualification`} onClick={() => { onBurgerMenuClick(); }}>
                                                                <div className='col-nav-list'>
                                                                    <div className='icon'>
                                                                        <img src={illustration12} alt='icon' />
                                                                    </div>
                                                                    <div className='content'>
                                                                        <div className='heading'>
                                                                            Lead Qualification
                                                                        </div>
                                                                    </div>
                                                                    <ArrowForwardIcon />
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className='col-12 col-sm-6 col-nav-item'>
                                                            <Link aria-current="page" to={`/features/document-management`} onClick={() => { onBurgerMenuClick(); }}>
                                                                <div className='col-nav-list'>
                                                                    <div className='icon'>
                                                                        <img src={illustration13} alt='icon' />
                                                                    </div>
                                                                    <div className='content'>
                                                                        <div className='heading'>
                                                                            Document Management
                                                                        </div>
                                                                    </div>
                                                                    <ArrowForwardIcon />
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className='col-12 col-sm-6 col-nav-item'>
                                                            <Link aria-current="page" to={`/features/calender-management`} onClick={() => { onBurgerMenuClick(); }}>
                                                                <div className='col-nav-list'>
                                                                    <div className='icon'>
                                                                        <img src={illustration14} alt='icon' />
                                                                    </div>
                                                                    <div className='content'>
                                                                        <div className='heading'>
                                                                            Calender Management
                                                                        </div>
                                                                    </div>
                                                                    <ArrowForwardIcon />
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className='col-12 col-sm-6 col-nav-item'>
                                                            <Link aria-current="page" to={`/features/lead-workflow`} onClick={() => { onBurgerMenuClick(); }}>
                                                                <div className='col-nav-list'>
                                                                    <div className='icon'>
                                                                        <img src={illustration15} alt='icon' />
                                                                    </div>
                                                                    <div className='content'>
                                                                        <div className='heading'>
                                                                            Lead workflow, escalations and tasks
                                                                        </div>
                                                                    </div>
                                                                    <ArrowForwardIcon />
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className='col-12 col-sm-6 col-nav-item'>
                                                            <Link aria-current="page" to={`/features/business-overview`} onClick={() => { onBurgerMenuClick(); }}>
                                                                <div className='col-nav-list'>
                                                                    <div className='icon'>
                                                                        <img src={illustration16} alt='icon' />
                                                                    </div>
                                                                    <div className='content'>
                                                                        <div className='heading'>
                                                                            Business overview dashboard
                                                                        </div>
                                                                    </div>
                                                                    <ArrowForwardIcon />
                                                                </div>
                                                            </Link>
                                                        </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </li>
                                {/*<div className='nav-divider'></div>*/}
                                {/*<li className="nav-item nav-divider nav-divider-left">
                                    <Link className={`nav-link ${active === '/about-us' ? 'active' : ''}`} aria-current="page" to="/about-us" onClick={() => { onBurgerMenuClick(); }}>About Us</Link>
                                </li>*/}
                                {/*<div className='nav-divider'></div>*/}
                                {/*<li className="nav-item nav-divider">
                                    <Link className={`nav-link ${active === '/why-use-us' ? 'active' : ''}`} aria-current="page" to="/why-use-us" onClick={() => { onBurgerMenuClick(); }}>Why Use Us</Link>
                                </li>*/}
                                {/*<div className='nav-divider'></div>*/}
                                {/*<li className="nav-item nav-divider">
                                    <Link className={`nav-link ${active === '/resources' ? 'active' : ''}`} aria-current="page" to="/resources" onClick={() => { onBurgerMenuClick(); }}>Resources</Link>
                                </li>*/}
                                {/*<div className='nav-divider'></div>*/}
                                <li className="nav-item nav-divider-left">
                                    <Link className={`nav-link ${active === '/contact-us' ? 'active' : ''}`} aria-current="page" to="/contact-us" onClick={() => { onBurgerMenuClick(); }}>Contact Us</Link>
                                </li>
                            </ul>
                            {/*<ul className="navbar-nav mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="btn btn-accent" aria-current="page" to="/enquire-now" onClick={() => { onBurgerMenuClick(); }}>ENQUIRE NOW</Link>
                                </li>
                            </ul>*/}
                        </div>
                    </div>
                </nav>
            </div>
        </Fragment>
    );
}

export default Navbar;

