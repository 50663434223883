// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimary": `#0E1436`,
	"colorSecondary": `#24D594`,
	"colorTertiary": `#063F4C`,
	"colorTertiaryLight": `#37656F`,
	"colorTertiaryLighter": `#698B93`,
	"colorAccent": `#DB296B`,
	"colorWhite": `#FFFFFF`,
	"colorBlack": `#000000`,
	"colorGrey": `#707376`,
	"colorGreyLight": `#DDDDDD`,
	"colorChart1": `#063F4C`,
	"colorChart2": `#24D594`,
	"colorChart3": `#007595`,
	"colorChart4": `#DB296B`,
	"colorChart5": `#0E1436`,
	"colorChart6": `#CBC573`,
	"white": `#FFFFFF`,
	"grey": `#707376`,
	"greyLight": `#DDDDDD`,
	"navy": `#0E1436`,
	"navyLighter1": `#262B4A`,
	"navyLighter2": `#3E425E`,
	"navyLighter3": `#565A72`,
	"navyLighter4": `#6E7286`,
	"greenDarker4": `#072A1D`,
	"greenDarker3": `#0E553B`,
	"greenDarker2": `#157F58`,
	"greenDarker1": `#1CAA76`,
	"green": `#24D594`,
	"greenLighter1": `#4FDDA9`,
	"greenLighter2": `#7BE5BE`,
	"greenLighter3": `#A7EED4`,
	"greenLighter4": `#D3F6E9`,
	"blueGreenDarker3": `#02191E`,
	"blueGreenDarker2": `#03252D`,
	"blueGreenDarker1": `#04323C`,
	"blueGreen": `#063F4C`,
	"blueGreenLighter1": `#37656F`,
	"blueGreenLighter2": `#698B93`,
	"blueGreenLighter3": `#9BB2B7`,
	"blueGreenLighter4": `#CDD8DB`
};
export default ___CSS_LOADER_EXPORT___;
