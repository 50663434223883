import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";

import './App.scss';

import Navbar from './components/navbar/Navbar';
import PublicRoutes from './routes/PublicRoutes';
import Footer from './components/footer/Footer';

//import TenantService from './services/TenantService';

import { newTracker, trackPageView/*, enableActivityTracking*/ } from '@snowplow/browser-tracker';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    newTracker(`accelerare-${process.env.REACT_APP_ENV}`, 'https://sp.accelerare.cloud', {
      appId: 'accelerare-website',
      postPath: '/zmcvnoqwiuehi',
    });
  }, []);

  useEffect(() => {
    //enableActivityTracking(30, 30);
    trackPageView();
  },[location])

  return (
    <div className="app">
      {<Navbar />}
      <div className='app-content container'>
        <PublicRoutes />
      </div>
      {<Footer />}
    </div>
  );
}

export default App;
