import React, { Fragment, useEffect, useState } from 'react';
import './ExperimentsPage.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import icon from '../../images/features/Experiments.svg';
import Title from '../../components/title/Title';
import colors from '../../_variables.module.scss';
import Card from '../../components/card/Card';
import illustration01 from '../../images/features/experiments/illustration01.svg';
import illustration02 from '../../images/features/experiments/illustration02.svg';
import illustration03 from '../../images/features/experiments/illustration03.svg';
import illustration04 from '../../images/features/experiments/illustration04.svg';
import illustration05 from '../../images/features/experiments/illustration05.svg';
import illustration06 from '../../images/features/experiments/illustration06.svg';


const ExperimentsPage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("Experiments");
        setList(
            <Fragment>
                <ul>
                    <li>Website experiments test <strong>variations</strong> like layouts, colours, or calls-to-action to find the <strong>best performer</strong>, and the empirical approach helps organisations <strong>refine strategies</strong> and <strong>optimise outcomes</strong> for <strong>continuous improvement</strong>.  Experiments can have many types, for example:</li>
                    <ul>
                        <li><strong>A/B Testing:</strong> Comparing different versions of website elements to determine which performs better for user engagement and conversions.</li>
                        <li><strong>Multivariate Testing:</strong> Simultaneously testing multiple variables to find the most effective combination for website performance.</li>
                    </ul>
                    <p><strong>Experiment Analysis</strong> draws insights and make <strong>data-driven decisions</strong> for further website improvements.</p>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={`Accelerare | ${title}`} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='experiments-page'>
                    <Title title={title} list={list} icon={icon} />
                    <div className='container first-section mt-5'>
                        <div className='heading'>The benefits of experiments:</div>
                        <div className='row'>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Data-Driven Decisions"}
                                    content={"Experiments yield data on user behaviour, guiding informed decisions."}
                                    backgroundColor={colors.navy}
                                    icon={illustration01}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Continuous Improvement"}
                                    content={"Iterative testing and analysis allow ongoing optimisation."}
                                    backgroundColor={colors.navy}
                                    icon={illustration02}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Increase Conversion"}
                                    content={"Effective experimentation enhances user experience, leading to higher engagement and conversions."}
                                    backgroundColor={colors.navy}
                                    icon={illustration03}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Risk Mitigation"}
                                    content={"Testing reduces the risk of negative impacts from changes."}
                                    backgroundColor={colors.navy}
                                    icon={illustration04}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Competitive Advantage"}
                                    content={"Experimentation fosters adaptability and keeps businesses ahead in their markets."}
                                    backgroundColor={colors.navy}
                                    icon={illustration05}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Insights Generation"}
                                    content={"Experiments reveal valuable user insights, guiding future optimisations and product development."}
                                    backgroundColor={colors.navy}
                                    icon={illustration06}
                                    headingColor={colors.white}
                                    contentColor={colors.white} />
                            </div>
                        </div>
                    </div>
                </div>
            </PageLayout>
        </Fragment >
    );
}

export default ExperimentsPage;