import React, { Fragment, useEffect, useState } from 'react';
import './WhatsAppIntegrationPage.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import icon from '../../images/features/WhatsAppIntegration.svg';
import Title from '../../components/title/Title';
import colors from '../../_variables.module.scss';
import List from '../../components/list/List';
import illustration01 from '../../images/features/whatsapp-integration/illustration01.svg';
import illustration02 from '../../images/features/whatsapp-integration/illustration02.svg';
import illustration03 from '../../images/features/whatsapp-integration/illustration03.svg';
import illustration04 from '../../images/features/whatsapp-integration/illustration04.svg';
import illustration05 from '../../images/features/whatsapp-integration/illustration05.svg';
import illustration06 from '../../images/features/whatsapp-integration/illustration06.svg';
import illustration07 from '../../images/features/whatsapp-integration/illustration07.svg';
import illustration08 from '../../images/features/whatsapp-integration/illustration08.svg';
import illustration09 from '../../images/features/whatsapp-integration/illustration09.svg';
import illustration10 from '../../images/features/whatsapp-integration/illustration10.svg';


const WhatsAppIntegrationPage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("WhatsApp Integration");
        setList(
            <Fragment>
                <ul>
                    <li>WhatsApp integration <strong>facilitates</strong> real-time <strong>communication</strong>, allowing <strong>instant messaging</strong> and <strong>media</strong> sharing.</li>
                    <li>Businesses can use the WhatsApp <strong>Business API</strong> for <strong>automated</strong> messaging,<strong> chatbots</strong>, and direct <strong>customer communication</strong>.</li>
                    <li>Integrating What0sApp with <strong>CRM</strong> systems helps <strong>manage</strong> customer <strong>interactions</strong> and provide <strong>personalised service</strong>.</li>
                    <li>WhatsApp supports <strong>end-to-end encryption</strong>, ensuring <strong>secure</strong> and <strong>private communication</strong> for users and businesses.</li>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={`Accelerare | ${title}`} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='whatsapp-integration-page'>
                    <Title title={title} list={list} icon={icon} />
                    <div className='container first-section mt-5'>
                        <div className='heading'>The benefits of WhatsApp integration:</div>
                        <List
                            heading={"Direct Communication"}
                            content={"Enables direct and instant communication with customers, facilitating real-time interactions, support, and feedback."}
                            icon={illustration01}
                            backgroundColor={colors.blueGreenDarker2}
                            headingColor={colors.white}
                            contentColor={colors.white} />
                        <List
                            heading={"Global Reach"}
                            content={"With a vast user base, WhatsApp provides businesses with a platform to reach a global audience, breaking down geographical barriers."}
                            icon={illustration02}
                            backgroundColor={colors.blueGreenDarker2}
                            headingColor={colors.white}
                            contentColor={colors.white} />
                        <List
                            heading={"Customer Support"}
                            content={"Businesses can use WhatsApp as a customer support channel, addressing queries, resolving issues, and providing assistance efficiently."}
                            icon={illustration03}
                            backgroundColor={colors.blueGreenDarker2}
                            headingColor={colors.white}
                            contentColor={colors.white} />
                        <List
                            heading={"Marketing and Promotions"}
                            content={"WhatsApp allows businesses to share updates, promotions, and marketing materials directly with customers, fostering engagement and driving sales."}
                            icon={illustration04}
                            backgroundColor={colors.blueGreenDarker2}
                            headingColor={colors.white}
                            contentColor={colors.white} />
                        <List
                            heading={"Automation"}
                            content={"Integration with chatbots and automated responses streamlines processes, providing quick answers to common queries and enhancing overall efficiency."}
                            icon={illustration05}
                            backgroundColor={colors.blueGreenDarker2}
                            headingColor={colors.white}
                            contentColor={colors.white} />
                        <List
                            heading={"Secure Communication"}
                            content={"WhatsApp employs end-to-end encryption, ensuring the security and privacy of business and customer communications."}
                            icon={illustration06}
                            backgroundColor={colors.blueGreenDarker2}
                            headingColor={colors.white}
                            contentColor={colors.white} />
                        <List
                            heading={"Rich Media Sharing"}
                            content={"Businesses can share multimedia content like images, videos, and documents, enhancing the richness of communication and marketing efforts."}
                            icon={illustration07}
                            backgroundColor={colors.blueGreenDarker2}
                            headingColor={colors.white}
                            contentColor={colors.white} />
                        <List
                            heading={"Appointment Scheduling"}
                            content={"Integrating WhatsApp with scheduling tools allows businesses to efficiently manage appointments and bookings."}
                            icon={illustration08}
                            backgroundColor={colors.blueGreenDarker2}
                            headingColor={colors.white}
                            contentColor={colors.white} />
                        <List
                            heading={"Personalised Engagement"}
                            content={"Businesses can send personalised messages and offers based on customer preferences, improving the overall customer experience."}
                            icon={illustration09}
                            backgroundColor={colors.blueGreenDarker2}
                            headingColor={colors.white}
                            contentColor={colors.white} />
                        <List
                            heading={"Cost-Effective"}
                            content={"Compared to traditional communication channels, WhatsApp integration can be a cost-effective solution for businesses to connect with their audience."}
                            icon={illustration10}
                            backgroundColor={colors.blueGreenDarker2}
                            headingColor={colors.white}
                            contentColor={colors.white} />
                    </div>
                </div>
            </PageLayout>
        </Fragment>
    );
}

export default WhatsAppIntegrationPage;