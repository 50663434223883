import React from 'react';
import './List.scss';

import colors from '../../_variables.module.scss';

const List = ({ heading = null, content = null, icon = null, backgroundColor = colors.green, headingColor = colors.white, contentColor = colors.white }) => {
    return (
        <div className='list d-flex flex-sm-row flex-column justify-content-sm-between' style={{ backgroundColor: backgroundColor }}>
            {icon && <div className='list-img'>
                <img src={icon} alt="icon" />
            </div>}
            {(heading || content) && <div className='list-container'>
                <div className='list-inner'>
                    {heading && <div className='list-heading' style={{ color: headingColor }}>
                        {heading}
                    </div>}

                    {content && <div className='list-content' style={{ color: contentColor }}>
                        {content}
                    </div>}
                </div>
            </div>}
        </div>
    );
};

export default List;