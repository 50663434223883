import React, { Fragment, useEffect, useState } from 'react';
import './HomePage.scss';
import { Link } from "react-router-dom";
import Card from '../components/card/Card';
import colors from '../_variables.module.scss';
import PageLayout from '../components/page-layout/PageLayout';
import Logo from '../logo_white_text.svg'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import illustration01 from '../images/home/illustration01.svg';
import illustration02 from '../images/home/illustration02.svg';
import illustration03 from '../images/home/illustration03.svg';
import illustration04 from '../images/home/illustration04.svg';
import illustration05 from '../images/home/illustration05.svg';
import illustration06 from '../images/home/illustration06.svg';
import illustration07 from '../images/home/illustration07.svg';
import illustration08 from '../images/home/illustration08.svg';
import illustration09 from '../images/home/illustration09.svg';
import illustration10 from '../images/home/illustration10.svg';
/*import illustration11 from '../images/home/illustration11.svg';
import illustration12 from '../images/home/illustration12.svg';
import illustration13 from '../images/home/illustration13.svg';
import illustration14 from '../images/home/illustration14.svg';
import illustration15 from '../images/home/illustration15.svg';
import illustration16 from '../images/home/illustration16.svg';*/

const HomePage = () => {

    const [featureAccordionOpen, setFeatureAccordionOpen] = useState(false);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 992 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 991, min: 768 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1
        }
    };

    const CustomRightArrow = ({ onClick }) => {
        return <button className="grid-carousel-button grid-carousel-right-button" type="button" onClick={() => onClick()} >
            <ArrowForwardIcon />
        </button>;
    };

    const CustomLeftArrow = ({ onClick }) => {
        return <button className="grid-carousel-button grid-carousel-left-button" type="button" onClick={() => onClick()} >
            <ArrowBackIcon />
        </button>;
    };


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
    }, []);

    return (
        <Fragment>
            <PageLayout title={"Accelerare | Home"} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='home-page'>
                    <div className='banner-component'>
                        {<div className='banner-title'>
                            <div className='title'>
                                <img src={Logo} width={"100px"} height={"100px"} alt="logo" />
                            </div>
                            <div className='subtitle'>Enable Velocity</div>
                            <div className='title-divider'></div>
                            <div className='list'>
                                <ul>
                                    <li>Our digital business platform <strong className="lighter-primary">seamlessly</strong> integrates various <strong className="lighter-primary">digital technologies</strong> to create novel <strong className="lighter-primary">business assets</strong> and essential <strong className="lighter-primary">capabilities for impactful</strong> digital <strong className="lighter-primary">transformation</strong>.</li>
                                    <li>It goes beyond <strong className="lighter-primary">traditional</strong> technology platforms by <strong className="lighter-primary">harnessing</strong> technologies like Artificial Intelligence <strong className="lighter-primary">(AI)</strong>, The Internet of Things <strong className="lighter-primary">(IoT)</strong>, Machine Learning <strong className="lighter-primary">(ML)</strong>, and Large Language models <strong className="lighter-primary">(LLM)</strong> to enable smart, data-driven <strong className="lighter-primary">decision-making</strong> and drive <strong className="lighter-primary">business success</strong> in today's dynamic landscape.</li>
                                    <li>Explore our <strong className="lighter-primary">offerings</strong> to unlock <strong className="lighter-primary">key</strong> business <strong className="lighter-primary">capabilities</strong> and fuel <strong className="lighter-primary">growth</strong>.</li>
                                </ul>
                                {/*<Link className="btn btn-accent" aria-current="page" to="/enquire-now" onClick={() => {  }}>GET A QUOTE</Link>*/}
                            </div>
                        </div>}
                    </div>
                    <div className='first-section'>
                        <div className="heading">
                            What we do
                        </div>
                        <div className='grid'>
                            <div className='row'>
                                <div className='col-12 col-md-6 col-lg-4 my-2'>
                                    <Card
                                        heading={null}
                                        icon={illustration01}
                                        content={"The fusion of various digitalisation technologies into a cohesive solution."}
                                        backgroundColor={colors.colorGreyLight}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </div>
                                <div className='col-12 col-md-6 col-lg-4 my-2'>
                                    <Card
                                        heading={null}
                                        icon={illustration02}
                                        content={"This fusion is intellectually orchestrated to enable the creation and growth of valuable digital business resources."}
                                        backgroundColor={colors.colorGreyLight}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </div>
                                <div className='col-12 col-md-6 col-lg-4 my-2'>
                                    <Card
                                        heading={null}
                                        icon={illustration03}
                                        content={"These digital business resources are integral facilitators of true digital transformation within business models and processes."}
                                        backgroundColor={colors.colorGreyLight}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='second-section'>
                        <div className="heading">
                            Domains
                        </div>
                        <div className='sub-heading'>
                            The domains we focus on to enable, improve and maintain an effective digital business platform.
                        </div>
                        <div className='grid-carousel'>
                            <Carousel responsive={responsive} customRightArrow={<CustomRightArrow />} customLeftArrow={<CustomLeftArrow />} draggable={true} swipeable={true}>
                                <Link to="/domains/lead-management">
                                    <Card
                                        heading={"Lead Management"}
                                        icon={illustration04}
                                        content={"The systematic process of acquiring, tracking, and nurturing potential customers from the initial point of interest to conversion."}
                                        backgroundColor={colors.white}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </Link>
                                <Link to="/domains/inventory-management">
                                    <Card
                                        heading={"Inventory Management"}
                                        icon={illustration05}
                                        content={"A full analysis of your business platform to deliver user experience driven design solutions."}
                                        backgroundColor={colors.white}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </Link>

                                <Link to="/domains/customer-relations">
                                    <Card
                                        heading={"Customer Relations"}
                                        icon={illustration06}
                                        content={"Guidance on the optimisation of customer interactions throughout their journey."}
                                        backgroundColor={colors.white}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </Link>
                                <Link to="/domains/listing-management">
                                    <Card
                                        heading={"Listing Management"}
                                        icon={illustration07}
                                        content={"Managing and maintaining the various online listings or profiles to ensure accuracy and consistency."}
                                        backgroundColor={colors.white}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </Link>
                                <Link to="/domains/website-management">
                                    <Card
                                        heading={"Website Management"}
                                        icon={illustration08}
                                        content={"Overseeing and maintaining a website to ensure its optimal functionality, security, and performance."}
                                        backgroundColor={colors.white}
                                        headingColor={colors.blueGreenDarker1}
                                        contentColor={colors.blueGreenDarker1} />
                                </Link>
                            </Carousel>
                        </div>
                        <div className='section-divider'></div>
                        <div className="heading clickable" onClick={() => setFeatureAccordionOpen(!featureAccordionOpen)}>
                            Features
                            {!featureAccordionOpen ?
                                <ArrowDropDownIcon className='drop-down-icon' style={{ color: colors.navy }} />
                                :
                                <ArrowDropUpIcon className='drop-down-icon' style={{ color: colors.navy }} />}
                        </div>
                        <div className='sub-heading'>
                            The distinct attributes inherent to our offered products.
                        </div>
                        {featureAccordionOpen &&
                            <div className='accordion-list'>
                                <div className='grid'>
                                    <div className='row'>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/experiments">
                                                <Card
                                                    heading={"Experiments"}
                                                    icon={illustration09}
                                                    content={"Data-driven insights, helping businesses make informed decisions to optimise strategies and achieve improved results."}
                                                    backgroundColor={colors.white}
                                                    headingColor={colors.blueGreenDarker1}
                                                    contentColor={colors.blueGreenDarker1} />
                                            </Link>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/whatsapp-integration">
                                                <Card
                                                    heading={"WhatsApp Integration"}
                                                    icon={illustration10}
                                                    content={"Empowers businesses with a versatile and powerful tool for communication, customer engagement, and marketing."}
                                                    backgroundColor={colors.white}
                                                    headingColor={colors.blueGreenDarker1}
                                                    contentColor={colors.blueGreenDarker1} />
                                            </Link>
                                        </div>
                                        {/*<div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/artificial-intelligence">
                                            <Card 
                                                heading={"Artificial Intelligence"}
                                                icon={illustration11}
                                                content={"Can lead to increased efficiency, better decision-making, improved customer experiences, and a competitive advantage."}
                                                backgroundColor={colors.navyLighter1}
                                                headingColor={colors.white}
                                                contentColor={colors.white} />
                                            </Link>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/lead-qualification">
                                                <Card
                                                    heading={"Lead Qualification"}
                                                    icon={illustration12}
                                                    content={"Evaluating and categorising potential customers based on their likelihood to convert into paying customers."}
                                                    backgroundColor={colors.navyLighter1}
                                                    headingColor={colors.white}
                                                    contentColor={colors.white} />
                                            </Link>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/document-management">
                                                <Card
                                                    heading={"Document Management"}
                                                    icon={illustration13}
                                                    content={"Efficiently create, organise, store, retrieve, and manage documents in a systematic and secure manner."}
                                                    backgroundColor={colors.navyLighter1}
                                                    headingColor={colors.white}
                                                    contentColor={colors.white} />
                                            </Link>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/calender-management">
                                                <Card
                                                    heading={"Calendar Management"}
                                                    icon={illustration14}
                                                    content={"Retrospective tracking of changes implemented."}
                                                    backgroundColor={colors.navyLighter1}
                                                    headingColor={colors.white}
                                                    contentColor={colors.white} />
                                            </Link>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/lead-workflow">
                                                <Card
                                                    heading={"Lead Workflow, Escalation & Tasks"}
                                                    icon={illustration15}
                                                    content={"A systematic process through which tasks are set to reach a goal and escalating issues to foster more efficient resolution thereof."}
                                                    backgroundColor={colors.navyLighter1}
                                                    headingColor={colors.white}
                                                    contentColor={colors.white} />
                                            </Link>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 my-2'>
                                            <Link to="/features/business-overview">
                                                <Card
                                                    heading={"Business Overview Dashboard"}
                                                    icon={illustration16}
                                                    content={"Visual representation of key performance indicators and critical metrics that provide a comprehensive snapshot of performance."}
                                                    backgroundColor={colors.navyLighter1}
                                                    headingColor={colors.white}
                                                    contentColor={colors.white} />
                                            </Link>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </PageLayout>
        </Fragment>
    );
}

export default HomePage;