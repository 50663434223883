import React, { Fragment } from 'react';
import './Title.scss';

const Title = ({ children, title = "Title", subtitle, center = false, list = null, icon = null }) => {
    return (
        <Fragment>
            <div className='title-component container'>
                <div className={`text-center mt-5 mb-5 title-divider ${center ? "title-center" : ""}`}>
                    <div className='main-title'>{title}</div>
                    {subtitle && <p>{subtitle}</p>}
                </div>
                {(list || icon) && <div className='d-flex flex-sm-row flex-column-reverse justify-content-sm-between gap-5'>
                    {list && list}
                    {icon && <img src={icon} alt="icon" />}
                </div>}
                {children}
            </div>
        </Fragment>
    );
}

export default Title;